import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'About Abadima', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome! There is more to see coming soon.', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hello! They call me',
  name: 'Abadima',
  subtitle: `I'm a simple person.`,
  cta: 'About Me',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.png',
  paragraphOne: `Hello! My name is Abadi, although I also go as Neko or Abadima on the internet. Overall, as said in the introduction, I am a really straightforward individual! Coding, sports, travelling, biking, developing, and other activities are among my interests. I strive to give my all in both the digital and physical worlds.`,
  paragraphTwo: 'What is the title of my dream career? Well, I\'m trying to become a commercial/cargo captain in the air; if I can do so with the right education and dedication, that would be fantastic! There is so much more to learn about my life, but I\'ll leave that to my mates.',
  paragraphThree: 'Quick notice! My discord username is Abadima#6356, if you see anyone that doesn\'t have that tag, it\'s not me!!',
  resume: 'https://linktr.ee/abadima', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'discordrpc.png',
    title: 'Discord Rich Presence',
    info: 'First open-source code I have made, this is to simplify Rich Presences, which allow you to add custom activities to your profile.',
    info2: '',
    url: 'https://abadima.gitbook.io/rpc/information/discord-rpc',
    repo: 'https://github.com/Abadima/RPC', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'comingsoon.jpeg',
    title: 'Abadima2',
    info: 'The Future of Discord Bots',
    info2: '',
    url: 'https://bot.abadima.ml/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'comingsoon.jpeg',
    title: 'Evaluation API',
    info: 'Let us run the code, so you don\'t have to ;)',
    info2: 'Open-Sourced to allow hosting capability.',
    url: 'https://eval.abadima2.tk/',
    repo: 'https://github.com/Abadima/Evaluate-API', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'banner.png',
    title: 'A.O.F Industries',
    info: 'Creating compelling Roblox Technologies.',
    info2: '*NOT AFFILIATED WITH ROBLOX CORP.',
    url: 'https://www.roblox.com/games/5152740231',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Wanna talk? Great.',
  btn: 'Email Me!',
  email: 'neko@abadima.dev',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/Abadima_Pro06',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/Abadima',
    },
    {
      id: nanoid(),
      name: 'twitch',
      url: 'https://twitch.tv/abadima_pro06',
    },
  ],
};

export const githubButtons = {
  isEnabled: false, 
};
